var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4lFysoY7JZTsBL1vqXasQ"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import {
  BrowserClient,
  BrowserTracing,
  getCurrentHub,
  makeFetchTransport,
} from '@sentry/nextjs';

getCurrentHub().bindClient(
  new BrowserClient({
    dsn: 'https://531115f419264fe69d2c2a4923e02b21@o1054028.ingest.sentry.io/6690311',
    // integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    integrations: [new BrowserTracing()],
    ignoreErrors: [
      'Non-Error exception captured',
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: Cancelled',
    ],
    enabled: process.env.NODE_ENV === 'production',
    environment: process.env.NODE_ENV === 'production' ? 'Production' : 'Dev',
    transport: makeFetchTransport,
  }),
);
